@import './theme.css';

// Font
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;900&display=swap');

// Primefaces
@import 'primeng/resources/primeng.css';
@import 'primeflex/primeflex.css';
@import 'primeicons/primeicons.css';

// Library styles
@import 'node_modules/ngx-spinner/animations/ball-fall.css';
@import 'node_modules/quill/dist/quill.snow.css';

// Our styles
* {
  font-family: 'Montserrat', sans-serif;
  padding: 0;
  margin: 0;
  @extend .scroll-style;
}

// Overrides
:root {
  --surface-ground: #f5f5f5;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.8rem 0.5rem;
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}

p-divider.surface-ground .p-divider-content {
  background: var(--surface-ground);
}

p-divider.surface-card .p-divider-content {
  background: var(--surface-card);
}

p-checkbox.border-circle .p-checkbox-box {
  border-radius: 50%;
}

p-avatar.object-fit-cover {
  img {
    object-fit: cover;
  }
}

.p-button.p-button-secondary.p-button-outlined {
  color: var(--text-color-secondary);
  border-color: var(--text-color-secondary);

  &:hover {
    color: var(--gray-600) !important;
    border-color: var(--gray-600) !important;
  }
}

.p-button {
  transition:
    background-color 0.2s,
    border-color 0.2s;

  &.p-button-secondary.p-button-text {
    .p-button-label {
      color: var(--text-color-secondary);
    }

    &:hover {
      .p-button-label {
        color: var(--text-color);
      }
    }
  }
}

.p-breadcrumb {
  border: none;

  ul {
    li {
      color: var(--gray-700);
      &:last-child {
        color: black !important;
        font-weight: 600;
      }
    }
  }
}

.p-menuitem-text {
  padding: 0 0.8rem;
  cursor: pointer;
}

.p-tabmenu .p-tabmenu-nav {
  background: transparent !important;
  border: none;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  background-color: transparent !important;
  opacity: 0.3;
  padding: 0.5rem;
  border-bottom: 4px solid #dee2e6;
  margin: 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  opacity: 1;
  border-bottom-width: 5px;
}

.p-tabmenu
  .p-tabmenu-nav
  .p-tabmenuitem
  .p-menuitem-link:not(.p-disabled):focus {
  opacity: 1;
  box-shadow: none;
}

.p-tabmenu .p-tabmenu-nav-btn.p-link {
  background: var(--surface-ground);
  box-shadow: none;

  &:focus {
    box-shadow: none;
  }
}

.p-tieredmenu.p-tieredmenu-overlay {
  margin-top: 0.5rem;
  border-radius: 10%;
}

.p-tieredmenu .p-menuitem {
  text-align: right;
  border-bottom: 0.1rem solid #ededed;
  font-weight: 500;
}

.p-tieredmenu .p-menuitem:last-child {
  border-bottom: none;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-text {
  margin: 0;
  padding: 0;
}

.p-tieredmenu .p-menuitem-link {
  flex-direction: row-reverse;
  padding: 0.75rem 0.25rem;
  gap: 1rem;
}

.p-accordion
  .p-accordion-header:not(.p-disabled)
  .p-accordion-header-link:focus {
  box-shadow: none !important;
}

.p-dropdown {
  .p-dropdown-trigger {
    color: var(--primary-color);
  }
}

.p-dropdown-panel {
  max-width: 50vw;

  @media (max-width: 768px) {
    max-width: 90vw;
  }

  .p-dropdown-item {
    span {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      white-space: break-spaces;
      word-break: break-word;
    }
  }
}

.p-dialog {
  max-height: 90vh;
  max-width: 90vw;

  &.fit {
    width: auto;
  }

  &.large {
    min-width: 40rem;
    width: 35vw;
    min-height: 40vh;

    @media (max-width: 768px) {
      min-width: 0;
      width: 90vw;
    }

    &--fixed-height {
      @extend .large;
      height: 80vh;
    }
  }

  &.huge {
    min-width: 40rem;
    width: 45vw;
    min-height: 40vh;

    @media (max-width: 768px) {
      min-width: 0;
      width: 90vw;
    }

    &--fixed-height {
      @extend .large;
      height: 80vh;
    }
  }

  &.fullscreen {
    min-width: 0;
    width: 90vw;
  }

  .p-dialog-header {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    border-bottom: 2px solid var(--gray-200);

    .p-dialog-title {
      max-width: 40vw;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
    }
  }

  .p-dialog-content:last-of-type {
    border-radius: 12px;
  }

  &:has(> .p-dialog-header) {
    .p-dialog-content:last-of-type {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }

  &.p-confirm-dialog {
    .p-dialog-content {
      padding-top: 1.5rem;

      .p-confirm-dialog-message {
        margin: 0;
      }
    }
  }
}

.p-row-not-selected {
  background: #fff !important;
}

.p-treetable .p-treetable-tbody > tr:focus {
  outline: none;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
  display: none !important;
}

.p-galleria .p-galleria-item-nav {
  background: rgba(0, 0, 0, 0.4);
}

.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.7);
}

// Custom Classes

.font-bolder {
  font-weight: 900;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.object-fit-cover {
  object-fit: cover;
}

.object-fit-contain {
  object-fit: contain;
}

.scale-y-0 {
  transform: scaleY(0);
}

.scale-y-1 {
  transform: scaleY(1);
}

.scale-x-0 {
  transform: scaleX(0);
}

.scale-x-1 {
  transform: scaleX(1);
}

.scroll-style {
  --sb-track-color: var(--surface-ground);
  --sb-thumb-color: var(--primary-color);
  --sb-size: 6px;

  &::-webkit-scrollbar {
    width: var(--sb-size);
    height: var(--sb-size);
  }

  &::-webkit-scrollbar-track {
    background: var(--sb-track-color);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--sb-thumb-color);
    border-radius: 8px;
  }
}

p-paginator.p-paginator-no-next-previous {
  .p-paginator-prev,
  .p-paginator-next {
    display: none;
  }
}

.time-input > .p-inputtext {
  width: 7rem;

  font-size: 0.9rem;
  color: var(--gray-500);
  text-align: center;

  border: none;
  border-radius: 0.75rem;
  background-color: var(--surface-100);
}

.button-disabled {
  cursor: auto !important;

  div,
  p {
    opacity: 0.5 !important;
    user-select: none !important;
  }
}

.cursor-auto {
  cursor: auto !important;
}

.internal-direction-column {
  div {
    display: flex;
    flex-direction: column !important;
  }
}

.template-columns-auto {
  display: grid !important;
  grid-template-columns: 32% 32% 32% !important;
  gap: 1rem !important;

  @media (max-width: 600px) {
    grid-template-columns: auto !important;
  }
}

.header-subtitle {
  transform: translateY(-1px);
}

.padding-0 {
  box-shadow: none;

  div {
    padding: 0 !important;
    background: transparent !important;
    border: none;

    button {
      display: none;
    }

    .p-galleria-thumbnail-items-container {
      margin-top: 2rem;
    }
  }
}

.p-options-button {
  background: #ededed;
  border: none;
  color: black;
  outline: none;

  &:enabled:hover {
    color: black;
  }
}

.red-option-tiered-menu span {
  color: #ef8277 !important;
}

.p-border-button {
  min-width: 20rem;
  max-width: 25rem;
  background: transparent;
  color: black;
  border: 0.1rem solid black;

  &:hover {
    background: transparent !important;
    color: black !important;
    border: 0.1rem solid black !important;
  }
}

.p-button-background-none {
  button {
    width: 100%;

    background-color: transparent;
    border: none;

    &:hover {
      background-color: var(--gray-300) !important;
    }

    &:focus {
      box-shadow: none !important;
    }
  }
}

.p-button-white {
  button {
    border: none;
    background-color: var(--surface-0);
    color: var(--text-color) !important;

    &:hover {
      background-color: var(--gray-300) !important;
    }

    &:focus {
      box-shadow: none !important;
    }
  }
}

.p-inputnumber-transparent {
  input {
    background: transparent;
    border: none;

    &:focus {
      box-shadow: none !important;
      border-color: none !important;
    }
  }
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.round-checkbox {
  .p-checkbox,
  .p-checkbox-box {
    border-radius: 100% !important;
  }
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.break-all-word {
  word-break: break-all;
}

.break-word {
  word-break: break-word;
}

.calendar-pointer {
  span > input {
    cursor: pointer;
  }
}

.button-icon-bg {
  input {
    border-right: none;
  }

  button {
    background-color: transparent !important;
    border-left: none;
    border-color: #ced4da;
  }
}

.button-icon-bg-gray {
  button {
    background-color: #f5f5f5 !important;
    border: none;
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
}

.tree-checkbox {
  .checkbox-container {
    display: inline-flex;

    position: relative;
    width: 2rem;

    cursor: pointer;
    font-size: 1.1rem;
    user-select: none;

    input {
      position: absolute;
      top: -0.7rem;
      opacity: 0;
      cursor: pointer;
      height: 22px;
      width: 22px;
    }

    .checkmark {
      position: absolute;
      top: -0.7rem;
      left: 0;
      width: 22px;
      height: 22px;
      background-color: transparent;
      border-radius: 0.5rem;
      border: 2px solid #ced4da;
    }

    &:hover .checkmark {
      border-color: #c2c2c2;
    }

    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    .checkmark.checkbox-selected:after {
      display: block;
    }

    .checkmark:after {
      left: 6px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      transition-duration: 0.2s !important;
    }

    .checkbox-selected {
      border-color: #ec8027 !important;
      background: #ec8027 !important;
    }
  }
}

.line-height-05 {
  line-height: 0.5rem;
}

.inner-html {
  > ul, ol {
    padding-left: 1rem;
  }
}
